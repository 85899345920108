import api from "./api";

export const tradeStatuses = [
  { id: 0, name: "Pending" },
  { id: 1, name: "Completed" },
  { id: 2, name: "Rejected " },
  { id: 3, name: "Invalid" },
];

export const getAllTrades = async (): Promise<any> => {
  const { data } = await api.get("/admin/trades");

  return data.trades;
};

export const getPendingTrades = async (): Promise<any> => {
  const { data } = await api.get("/admin/trades", {
    params: { pending: true },
  });

  return data.trades;
};

export const getTrade = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/trades/${id}`);

  return data.trade;
};

export const getTradeStatus = (status: number): any => {
  return tradeStatuses.find((s) => s.id === status)?.name;
};

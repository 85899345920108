import { useQuery } from "react-query";
import { useNavigate, Link } from "react-router-dom";

import { getAllUsers } from "../../../../services/user";
import BaseButton from "../../../button/BaseButton";
import WarningMessage from "../../../form/WarningMessage";

import Table from "../../../layout/Table";

const Users: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { data: users } = useQuery("users", getAllUsers, {
    keepPreviousData: true,
  });

  const handleCreateNewClick = () => {
    navigate("/manage/users/new");
  };

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-semibold">Manage Users</h3>
      <p className="text-md text-gray-500">
        Create and manage users to have access to this system, update email
        addresses or reset passwords.
      </p>
      <WarningMessage>
        Note that a user and an extraction right are not the same. An irrigator
        is not a user. If you want to add or edit extraction rights{" "}
        <Link to="/manage/extractionRights" className="font-semibold underline">
          go here
        </Link>
        .
      </WarningMessage>
      <Table
        fields={[
          { title: "ID", name: "id", linkTo: "/manage/users/:id" },
          { title: "Name", name: "name" },
          { title: "Email", name: "email" },
          { title: "Admin", name: "isAdmin" },
        ]}
        data={users?.map((u: any) => ({ ...u, isAdmin: String(u.isAdmin) }))}
        noRowsText="No Users Data"
      />
      <div className="flex w-full">
        <BaseButton
          type="button"
          className="flex items-center ml-auto px-3 py-2"
          onClick={handleCreateNewClick}
        >
          <i className="fal fa-plus mr-2" aria-hidden="true" />
          Create New
        </BaseButton>
      </div>
    </div>
  );
};

export default Users;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import TextInput from "../form/TextInput";
import ErrorMessage from "../form/ErrorMessage";
import Card from "../layout/Card";
import MainLayout from "../layout/MainLayout";

import { logIn } from "../../services/auth";
import { useAppContext } from "../../context/AppContext";
import PrimaryButton from "../button/PrimaryButton";

const Login: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const appContext = useAppContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const handleLogIn = async (e: any) => {
    e.preventDefault();

    try {
      const { user, token } = await logIn({ email, password });

      appContext.setLoggedInInfo({
        isLoggedIn: true,
        token,
        userDetails: user,
      });

      localStorage.setItem("token", token);
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("userDetails", JSON.stringify(user));

      navigate("/");
    } catch (e) {
      appContext.clearLoggedInInfo();
      setError(true);
    }
  };

  return (
    <MainLayout allowAnonymous={true}>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <Card
          className="max-w-md w-full space-y-8"
          header={
            <h3 className="text-xl">Log Into Water Ledger Administration</h3>
          }
        >
          <p className="mt-2 text-center text-md text-gray-500">
            Enter your email address and password
          </p>
          <form className="mt-8 space-y-6" onSubmit={handleLogIn}>
            {error && <ErrorMessage message="Login failed" />}
            <div className="-space-y-px">
              <div className="flex items-center py-2">
                <label className="w-1/3" htmlFor="email-address">
                  Email
                </label>
                <TextInput
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="flex items-center py-2">
                <label className="w-1/3" htmlFor="password">
                  Password
                </label>
                <TextInput
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="flex w-full">
              <PrimaryButton
                type="submit"
                className="flex items-center ml-auto"
                disabled={!email || !password}
              >
                Log In
                <i className="fal fa-sign-in ml-2" aria-hidden="true" />
              </PrimaryButton>
            </div>
          </form>
        </Card>
      </div>
    </MainLayout>
  );
};

export default Login;

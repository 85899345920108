import classNames from "classnames";

interface IDividerProps {
  className?: string;
}

const Divider: React.FunctionComponent<IDividerProps> = ({ className }) => {
  return (
    <div className={classNames("relative", className)}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300"></div>
      </div>
    </div>
  );
};

export default Divider;

import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { addExtractionRight } from "../../../../services/extractionRights";
import { getAllLevel1Resources } from "../../../../services/level1Resource";

import PrimaryButton from "../../../button/PrimaryButton";
import ErrorMessage from "../../../form/ErrorMessage";
import Select from "../../../form/Select";
import TextInput from "../../../form/TextInput";

const ExtractionRightAdd: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [newExtractionRight, setNewExtractionRight] = useState({} as any);
  const { mutateAsync: saveExtractionRightData, error } = useMutation<any, any>(
    (extractionRightData) => addExtractionRight(extractionRightData)
  );
  const { data: level1Resources } = useQuery<
    Array<{ id: string; name: string; identifier: string }>
  >("level1resources", getAllLevel1Resources);

  const handleInputChange = (field: string, value: any) => {
    setNewExtractionRight({
      ...newExtractionRight,
      [field]: value,
    });
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    await saveExtractionRightData(newExtractionRight);

    navigate("/manage/extractionRights");
  };

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-semibold">Create New Extraction Right</h3>
      <p className="text-md text-gray-500">
        Add an Extraction Right first, and then add the water accounts attached
        to the extractionRight after it has been saved.
      </p>
      <form className="space-y-8" onSubmit={handleSave}>
        <div className="space-y-8 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            {error && (
              <ErrorMessage
                message="Save failed"
                details={[
                  error?.response?.data?.errors?.[0]?.message ||
                    error?.response?.data?.message,
                ]}
              />
            )}

            <div className="space-y-3 sm:space-y-2">
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="level1ResourceId"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Level 1 Resource
                </label>
                <div className="sm:col-span-3">
                  <Select
                    name="level1ResourceId"
                    value={newExtractionRight.level1ResourceId}
                    onChange={(e) =>
                      handleInputChange("level1ResourceId", e.target.value)
                    }
                  >
                    <option></option>
                    {level1Resources?.map((rsc) => (
                      <option key={rsc.id} value={rsc.id}>
                        {rsc.name} - {rsc.identifier}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="extractionRight"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Extraction Right
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="extractionRight"
                    value={newExtractionRight.extractionRight}
                    onChange={(e) =>
                      handleInputChange("extractionRight", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="name"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="name"
                    value={newExtractionRight.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="email"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="email"
                    type="email"
                    value={newExtractionRight.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-2 justify-end">
          <PrimaryButton className="flex items-center  px-3 py-2" type="submit">
            <i className="fad fa-cloud-upload mr-2" />
            Save
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default ExtractionRightAdd;

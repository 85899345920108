import api from "./api";

export const extractionRightStatuses = [
  { id: 1, name: "Pending" },
  { id: 2, name: "Active" },
  { id: 3, name: "Cancelled " },
  { id: 4, name: "Deleted" },
];

export const getAllExtractionRight = async (): Promise<any> => {
  const { data } = await api.get("/admin/extractionRights");

  return data.extractionRights;
};

export const getPendingExtractionRight = async (): Promise<any> => {
  const { data } = await api.get("/admin/extractionRights", {
    params: { pending: true },
  });

  return data.extractionRights;
};

export const getExtractionRight = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/extractionRights/${id}`);

  return data.extractionRight;
};

export const getExtractionRightStatus = (status: number): any => {
  return extractionRightStatuses.find((s) => s.id === status)?.name;
};

export const saveExtractionRight = async (
  extractionRight: any
): Promise<any> => {
  const { data } = await api.put(
    `/admin/extractionRights/${extractionRight.id}`,
    extractionRight
  );

  return data.extractionRight;
};

export const addExtractionRight = async (
  extractionRight: any
): Promise<any> => {
  const { data } = await api.post("/admin/extractionRights", extractionRight);

  return data.extractionRight;
};

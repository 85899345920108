import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getPendingTrades } from "../../../services/trade";
import { formatDate } from "../../../utils/formatDate";
import { formatKilolitres } from "../../../utils/formatKilolitres";
import Card from "../../layout/Card";
import Table from "../../layout/Table";

const PendingTrades: React.FunctionComponent = () => {
  const { data: trades } = useQuery(
    ["trades", { pending: true }],
    getPendingTrades,
    { keepPreviousData: true }
  );

  return (
    <Card header="Trades Pending Approval" className="w-full">
      <Table
        fields={[
          { title: "Trade ID", name: "id" },
          { title: "Price (/ML)", name: "price" },
          { title: "Volume", name: "quantity" },
          { title: "From Level 0 WRS", name: "sellerLevel0ResourceName" },
          { title: "To Level 0 WRS", name: "buyerLevel0ResourceName" },
          { title: "Date", name: "createdAt" },
        ]}
        data={trades?.map((tr: any) => ({
          ...tr,
          id: (
            <Link
              to={`/manage/trades/${tr.id}`}
              className="underline text-blue-700 hover:text-blue-500"
            >
              {tr.id}
            </Link>
          ),
          price: `$${tr.price}`,
          quantity: formatKilolitres(tr.quantity),
          sellerLevel0ResourceName: tr.sellerAccount.level0Resource.name,
          buyerLevel0ResourceName: tr.buyerAccount.level0Resource.name,
          createdAt: formatDate(tr.createdAt, "d MMMM yyyy"),
        }))}
        noRowsText="There are no pending trades at the moment"
      />
    </Card>
  );
};

export default PendingTrades;

import { useState } from "react";
import { useQuery } from "react-query";
import { getAllLevel0Resources } from "../../../../services/level0Resource";
import { formatKilolitres } from "../../../../utils/formatKilolitres";
import BaseButton from "../../../button/BaseButton";
import Table from "../../../layout/Table";
import ExtractionRightDetailsAccountsAdd from "./ExtractionRightDetailsAccountsAdd";

interface IExtractionRightDetailsAccountsProps {
  extractionRight: any;
  onLevel0ResourceAdded: () => void;
}

const ExtractionRightDetailsAccounts: React.FunctionComponent<
  IExtractionRightDetailsAccountsProps
> = ({ extractionRight, onLevel0ResourceAdded }) => {
  const [editMode, setEditMode] = useState(false);
  const { data: level0Resources } = useQuery(
    "level0Resources",
    getAllLevel0Resources
  );

  return (
    <>
      <h3 className="text-xl font-semibold">Accounts</h3>
      <Table
        fields={[
          { title: "Account", name: "waterAccount" },
          { title: "Allocation", name: "allocation" },
          { title: "Balance", name: "balance" },
          { title: "Level 0 WRS", name: "level0ResourceName" },
        ]}
        data={extractionRight?.accounts?.map((acc: any) => {
          const accountLevel0Resource = level0Resources?.find(
            (z: any) => z.id === acc.level0ResourceId
          );

          return {
            ...acc,
            allocation: formatKilolitres(acc.allocation),
            balance: formatKilolitres(acc.balance),
            level0ResourceName: accountLevel0Resource?.name,
          };
        })}
        noRowsText="No accounts added"
      />
      {!editMode && (
        <div className="flex w-full">
          <BaseButton
            type="button"
            className="flex items-center ml-auto px-3 py-2"
            onClick={() => setEditMode(true)}
          >
            <i className="fal fa-plus mr-2" aria-hidden="true" />
            Add Account
          </BaseButton>
        </div>
      )}
      {editMode && (
        <ExtractionRightDetailsAccountsAdd
          extractionRight={extractionRight}
          onSaveClick={() => {
            setEditMode(false);
            onLevel0ResourceAdded();
          }}
          onCancelClick={() => setEditMode(false)}
        />
      )}
    </>
  );
};

export default ExtractionRightDetailsAccounts;

import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  addLevel1Resource,
  platforms,
} from "../../../../services/level1Resource";
import PrimaryButton from "../../../button/PrimaryButton";
import ErrorMessage from "../../../form/ErrorMessage";
import Select from "../../../form/Select";
import TextInput from "../../../form/TextInput";

const Level1ResourceAdd: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [newLevel1Resource, setNewLevel1Resource] = useState({
    platform: 1,
  } as any);
  const { mutateAsync: saveLevel1ResourceData, error } = useMutation<any, any>(
    (level1ResourceData) => addLevel1Resource(level1ResourceData)
  );

  const handleInputChange = (field: string, value: any) => {
    setNewLevel1Resource({
      ...newLevel1Resource,
      [field]: value,
    });
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    await saveLevel1ResourceData(newLevel1Resource);

    navigate("/manage/level1resources");
  };

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-semibold">Create New Level 1 Resource</h3>
      <p className="text-md text-gray-500">
        Create the base level 1 WRS entity. This must be created and saved
        before you can add any level 0 WRS to finish defining the Level 1 WRS
        structure.
      </p>
      <form className="space-y-8" onSubmit={handleSave}>
        <div className="space-y-8 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            {error && (
              <ErrorMessage
                message="Save failed"
                details={[
                  error?.response?.data?.errors?.[0]?.message ||
                    error?.response?.data?.message,
                ]}
              />
            )}

            <div className="space-y-3 sm:space-y-2">
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="name"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="name"
                    value={newLevel1Resource.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="identifier"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Identifier
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="identifier"
                    value={newLevel1Resource.identifier}
                    onChange={(e) =>
                      handleInputChange("identifier", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="platform"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Platform
                </label>
                <div className="sm:col-span-3">
                  <Select
                    name="platform"
                    value={newLevel1Resource.platform}
                    onChange={(e) =>
                      handleInputChange("platform", Number(e.target.value))
                    }
                  >
                    {platforms.map((p) => (
                      <option key={p.id} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="image"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Image
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="image"
                    value={newLevel1Resource.image}
                    onChange={(e) => handleInputChange("image", e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-2 justify-end">
          <PrimaryButton className="flex items-center  px-3 py-2" type="submit">
            <i className="fad fa-cloud-upload mr-2" />
            Save
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default Level1ResourceAdd;

import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

const DangerButton: React.FunctionComponent<
  ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, className, ...props }) => {
  return (
    <button
      className={classNames(
        "inline-flex items-center px-6 py-3 border border-red-700 text-base font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default DangerButton;

import React from "react";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../../context/AppContext";

import PrimaryButton from "../button/PrimaryButton";
import Card from "../layout/Card";
import MainLayout from "../layout/MainLayout";

const Logout: React.FunctionComponent = () => {
  const appContext = useAppContext();
  const navigate = useNavigate();

  const handleLogout = (e: any) => {
    e.preventDefault();

    appContext.clearLoggedInInfo();

    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("token");
    localStorage.removeItem("userDetails");

    navigate("/login");
  };

  return (
    <MainLayout>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <Card
          className="max-w-md w-full space-y-8"
          header={<h3 className="text-xl">Log Out</h3>}
        >
          <p className="mt-2 text-center text-md text-gray-500">
            Log out of the Water Ledger administration system
          </p>
          <form className="mt-8 space-y-6" onSubmit={handleLogout}>
            <div className="flex w-full">
              <PrimaryButton
                type="submit"
                className="flex items-center mx-auto"
              >
                Log Out
                <i className="fal fa-sign-in ml-2" aria-hidden="true" />
              </PrimaryButton>
            </div>
          </form>
        </Card>
      </div>
    </MainLayout>
  );
};

export default Logout;

import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { getOrder } from "../../../../services/order";
import { formatKilolitres } from "../../../../utils/formatKilolitres";
import { formatDate } from "../../../../utils/formatDate";
import Divider from "../../../layout/Divider";
import DescriptionList from "../../../layout/DescriptionList";

const OrderDetails: React.FunctionComponent = () => {
  const { id } = useParams();
  const { data: order } = useQuery(
    ["orders", { id }],
    () => id && getOrder(id),
    { keepPreviousData: true }
  );

  return (
    <div className="flex flex-col gap-4 w-full">
      <h3 className="text-2xl font-semibold">View Order Details</h3>
      <DescriptionList
        fields={[
          { title: "ID", name: "id" },
          { title: "Type", name: "type" },
          { title: "Price", name: "price" },
          { title: "Quantity", name: "quantity" },
          { title: "Created", name: "createdAt" },
          { title: "Accepted", name: "acceptedAt" },
        ]}
        data={{
          ...order,
          price: `$${order?.price}`,
          quantity: formatKilolitres(order?.quantity),
          createdAt: formatDate(order?.createdAt, "d MMMM yyyy @ hh:mm a"),
          acceptedAt:
            formatDate(order?.acceptedAt, "d MMMM yyyy @ hh:mm a") ||
            "Not Accepted",
        }}
      />
      <Divider />
      <h3 className="text-xl font-semibold">Blockchain</h3>
      <DescriptionList
        fields={[
          { title: "Remote Id", name: "remoteId" },
          { title: "Creation Hash", name: "creationTx" },
        ]}
        data={order}
      />
    </div>
  );
};

export default OrderDetails;

import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";

import { getContract } from "../../../../services/contract";
import ContractDetailsShow from "./ContractDetailsShow";
import Divider from "../../../layout/Divider";
import ContractDetailsEdit from "./ContractDetailsEdit";
import ContractDetailsDeployments from "./ContractDetailsDeployments";

const ContractDetails: React.FunctionComponent = () => {
  const { id } = useParams();
  const { data: contract, refetch: fetchContractData } = useQuery(
    ["contracts", { id }],
    () => id && getContract(id),
    { keepPreviousData: true }
  );
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="flex flex-col gap-4 w-full">
      <h3 className="text-2xl font-semibold">{contract?.contractName}</h3>
      {editMode ? (
        <ContractDetailsEdit
          contract={contract}
          onSaveClick={() => {
            setEditMode(false);
            fetchContractData();
          }}
          onCancelClick={() => setEditMode(false)}
        />
      ) : (
        <ContractDetailsShow
          contract={contract}
          onEditClick={() => setEditMode(true)}
        />
      )}
      <Divider />
      <ContractDetailsDeployments
        contract={contract}
        onDeploymentAdd={() => fetchContractData()}
        onDeploymentDelete={() => fetchContractData()}
      />
    </div>
  );
};

export default ContractDetails;

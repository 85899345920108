import { InputHTMLAttributes } from "react";

interface ISelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  children: React.ReactNode;
}

const Select: React.FunctionComponent<ISelectProps> = ({
  children,
  ...props
}) => {
  return (
    <select
      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
      {...props}
    >
      {children}
    </select>
  );
};

export default Select;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../../context/AppContext";
import MainHeader from "./MainHeader";

interface IMainLayoutProps {
  allowAnonymous?: boolean;
  children: React.ReactNode;
}

const MainLayout: React.FunctionComponent<IMainLayoutProps> = ({
  children,
  allowAnonymous = false,
}) => {
  const appContext = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    const userDetails = JSON.parse(
      localStorage.getItem("userDetails") || "null"
    );
    const token = localStorage.getItem("token") || "null";
    const isLoggedIn = JSON.parse(
      localStorage.getItem("isLoggedIn") || "false"
    );

    if (isLoggedIn) {
      appContext.setLoggedInInfo({
        userDetails,
        token,
        isLoggedIn,
      });
    } else if (!allowAnonymous) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-h-full w-full">
      <MainHeader />
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">{children}</div>
      </main>
    </div>
  );
};

export default MainLayout;

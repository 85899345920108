import { useQuery } from "react-query";

import {
  getAllLiabilities,
  getLiabilityStatus,
} from "../../../../services/liability";
import { formatDate } from "../../../../utils/formatDate";

import Table from "../../../layout/Table";

const Liabilities: React.FunctionComponent = () => {
  const { data: liabilities } = useQuery("liabilities", getAllLiabilities, {
    keepPreviousData: true,
  });

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-semibold">Manage Liabilities</h3>
      <p className="text-md text-gray-500">
        List all liabilities within the Water Ledger database. Note that it is
        not possible to add or edit, as this must be done through the smart
        contracts.
      </p>
      <Table
        fields={[
          { title: "ID", name: "id", linkTo: "/manage/liabilities/:id" },
          { title: "From", name: "seller" },
          { title: "To", name: "buyer" },
          { title: "Amount", name: "price" },
          { title: "Status", name: "status" },
          { title: "Date", name: "createdAt" },
        ]}
        data={liabilities?.map((l: any) => ({
          ...l,
          price: `$${l.price}`,
          status: getLiabilityStatus(l.status),
          createdAt: formatDate(l.createdAt, "d MMMM yyyy"),
        }))}
        noRowsText="No Liabilities Data"
      />
    </div>
  );
};

export default Liabilities;

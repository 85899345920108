import api from "./api";

export const getAllLevel0Resources = async (): Promise<any> => {
  const { data } = await api.get("/admin/level0Resources");

  return data.level0Resources;
};

export const addLevel0Resource = async (level0Resource: any): Promise<any> => {
  const { data } = await api.post("/admin/level0Resources", level0Resource);

  return data.level0Resource;
};

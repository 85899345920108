import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { getAllLevel1Resources } from "../../../../services/level1Resource";
import BaseButton from "../../../button/BaseButton";

import Table from "../../../layout/Table";

const Level1Resources: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { data: level1Resources } = useQuery(
    "level1resources",
    getAllLevel1Resources,
    {
      keepPreviousData: true,
    }
  );

  const handleCreateNewClick = () => {
    navigate("/manage/level1resources/new");
  };

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-semibold">Manage Level 1 WRS</h3>
      <p className="text-md text-gray-500">
        Edit level 1 WRS to modify details or add level 0 WRS before deployment
        of contracts.
      </p>
      <Table
        fields={[
          { title: "ID", name: "id", linkTo: "/manage/level1resources/:id" },
          { title: "Name", name: "name" },
          { title: "Identifier", name: "identifier" },
        ]}
        data={level1Resources}
        noRowsText="No Level 1 Resources Data"
      />
      <div className="flex w-full">
        <BaseButton
          type="button"
          className="flex items-center ml-auto px-3 py-2"
          onClick={handleCreateNewClick}
        >
          <i className="fal fa-plus mr-2" aria-hidden="true" />
          Create New
        </BaseButton>
      </div>
    </div>
  );
};

export default Level1Resources;

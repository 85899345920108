import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { addDeployment } from "../../../../services/deployment";
import { getAllLevel1Resources } from "../../../../services/level1Resource";

import BaseButton from "../../../button/BaseButton";
import PrimaryButton from "../../../button/PrimaryButton";
import ErrorMessage from "../../../form/ErrorMessage";
import Select from "../../../form/Select";
import TextInput from "../../../form/TextInput";
import Card from "../../../layout/Card";

interface IContractDetailsDeploymentsAddProps {
  contract: any;
  onStartDeployment: (newDeployment: any) => void;
  onSaveClick: () => void;
  onCancelClick: () => void;
}

const ContractDetailsDeploymentsAdd: React.FunctionComponent<
  IContractDetailsDeploymentsAddProps
> = ({ contract, onStartDeployment, onSaveClick, onCancelClick }) => {
  const { mutateAsync: addContractDeployment, error } = useMutation<any, any>(
    (deploymentData) => addDeployment(deploymentData)
  );
  const [newDeployment, setNewDeployment] = useState({
    contractId: contract.id,
    contractName: contract.contractName,
  } as any);
  const { data: level1Resources } = useQuery<
    Array<{ id: string; name: string; identifier: string }>
  >("level1resources", getAllLevel1Resources);

  const handleInputChange = (field: string, value: any) => {
    if (field === "level1ResourceId") {
      const selectedLevel1Resource = level1Resources?.find(
        (s: any) => s.id === value
      );

      if (selectedLevel1Resource) {
        newDeployment.identifier = selectedLevel1Resource.identifier;
        newDeployment.description = `${contract.contractName} for ${selectedLevel1Resource.identifier}`;
      }
    }

    setNewDeployment({
      ...newDeployment,
      [field]: value,
    });
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    onStartDeployment(newDeployment);

    await addContractDeployment(newDeployment);

    onSaveClick();
  };

  return (
    <Card header="Add Deployment Details" className="bg-gray-50">
      <form className="mt-2 space-y-8" onSubmit={handleSave}>
        <div className="space-y-8 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            {error && (
              <ErrorMessage
                message="Save failed"
                details={[
                  error?.response?.data?.errors?.[0]?.message ||
                    error?.response?.data?.message,
                ]}
              />
            )}

            <div className="space-y-3 sm:space-y-2">
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="level1ResourceId"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Level 1 WRS
                </label>
                <div className="sm:col-span-3">
                  <Select
                    name="level1ResourceId"
                    value={newDeployment.level1ResourceId}
                    onChange={(e) => {
                      handleInputChange("level1ResourceId", e.target.value);
                    }}
                  >
                    <option></option>
                    {level1Resources?.map((s: any) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="identifier"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Identifier
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="identifier"
                    value={newDeployment.identifier}
                    onChange={(e) =>
                      handleInputChange("identifier", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="description"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Description
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="description"
                    value={newDeployment.description}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-2 justify-end">
          <BaseButton
            className="flex items-center px-3 py-2"
            type="button"
            onClick={onCancelClick}
          >
            <i className="fal fa-times mr-2" />
            Cancel
          </BaseButton>
          <PrimaryButton className="flex items-center  px-3 py-2" type="submit">
            <i className="fad fa-cloud-upload mr-2" />
            Deploy Contract
          </PrimaryButton>
        </div>
      </form>
    </Card>
  );
};

export default ContractDetailsDeploymentsAdd;

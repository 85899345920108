import classNames from "classnames";
import { Link, generatePath } from "react-router-dom";

interface IDescriptionListProps {
  className?: string;
  fields: Array<{
    title: string;
    name: string;
    linkTo?: string;
    code?: boolean;
    hidden?: boolean;
  }>;
  data: Record<string, any>;
}

const DescriptionList: React.FunctionComponent<IDescriptionListProps> = ({
  className,
  fields,
  data,
}) => {
  return (
    <div className={classNames("border-t border-gray-200", className)}>
      <dl>
        {fields
          ?.filter((f) => !f.hidden)
          .map((lt, i) => (
            <div
              key={lt.name}
              className={classNames(
                {
                  "bg-gray-50": i % 2 === 0,
                  "bg-white": i % 2 !== 0,
                },
                "p-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              )}
            >
              <dt className="text-sm font-medium text-gray-500">{lt.title}</dt>
              <dd
                className={classNames(
                  {
                    "whitespace-pre": lt.code,
                  },
                  "mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                )}
              >
                {lt.linkTo ? (
                  <Link
                    to={generatePath(lt.linkTo, data)}
                    className="underline hover:text-gray-400"
                  >
                    {data?.[lt.name]}
                  </Link>
                ) : (
                  data?.[lt.name]
                )}
              </dd>
            </div>
          ))}
      </dl>
    </div>
  );
};

export default DescriptionList;

import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { addUser } from "../../../../services/user";
import PrimaryButton from "../../../button/PrimaryButton";
import ErrorMessage from "../../../form/ErrorMessage";
import TextInput from "../../../form/TextInput";

const UserAdd: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [newUser, setNewUser] = useState({} as any);
  const { mutateAsync: saveUserData, error } = useMutation<any, any>(
    (userData) => addUser(userData)
  );

  const handleInputChange = (field: string, value: any) => {
    setNewUser({
      ...newUser,
      [field]: value,
    });
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    await saveUserData(newUser);

    navigate("/manage/users");
  };

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-semibold">Create New User</h3>
      <p className="text-md text-gray-500">
        Create a new user. Enter the following details to give a user access to
        the system
      </p>
      <form className="space-y-8" onSubmit={handleSave}>
        <div className="space-y-8 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            {error && (
              <ErrorMessage
                message="Save failed"
                details={[
                  error?.response?.data?.errors?.[0]?.message ||
                    error?.response?.data?.message,
                ]}
              />
            )}

            <div className="space-y-3 sm:space-y-2">
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="name"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="name"
                    value={newUser.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="email"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="email"
                    type="email"
                    value={newUser.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="password"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Password
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="password"
                    type="password"
                    value={newUser.password}
                    onChange={(e) =>
                      handleInputChange("password", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-2 justify-end">
          <PrimaryButton className="flex items-center  px-3 py-2" type="submit">
            <i className="fad fa-cloud-upload mr-2" />
            Save
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default UserAdd;

import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { deleteDeployment } from "../../../../services/deployment";

import BaseButton from "../../../button/BaseButton";
import DangerButton from "../../../button/DangerButton";
import Table from "../../../layout/Table";
import ContractDetailsDeploymentsAdd from "./ContractDetailsDeploymentsAdd";

interface IContractDetailsDeploymentsProps {
  contract: any;
  onDeploymentAdd: () => void;
  onDeploymentDelete: () => void;
}

const ContractDetailsDeployments: React.FunctionComponent<
  IContractDetailsDeploymentsProps
> = ({ contract, onDeploymentAdd, onDeploymentDelete }) => {
  const [editMode, setEditMode] = useState(false);
  const [deployments, setDeployments] = useState([{ address: <></> }]);
  const { mutateAsync: deleteContractDeployment } = useMutation((id: string) =>
    deleteDeployment(id)
  );

  const handleStartDeployment = (newDeployment: any) => {
    setDeployments([
      ...deployments,
      {
        ...newDeployment,
        address: (
          <div className="flex">
            <i className="fal fa-spinner fa-spin mr-2" />
            Mining blockchain transaction. This may take up to a minute.
          </div>
        ),
      },
    ]);
  };

  const handleDeleteClick = async (id: string) => {
    await deleteContractDeployment(id);

    onDeploymentDelete();
  };

  useEffect(() => {
    if (contract?.deployments) {
      setDeployments(
        contract.deployments.map((d: any) => ({
          ...d,
          address: d.details.address,
          delete: (
            <DangerButton
              className="py-1 px-2 text-xs"
              onClick={() => handleDeleteClick(d.id)}
            >
              Delete
            </DangerButton>
          ),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract?.deployments]);

  return (
    <>
      <h3 className="text-xl font-semibold">Deployments</h3>
      <Table
        fields={[
          { title: "Identifier", name: "identifier" },
          { title: "Description", name: "description" },
          { title: "Address", name: "address" },
          { title: "", name: "delete" },
        ]}
        data={deployments}
        noRowsText="No Deployments Found"
      />
      {!editMode && (
        <div className="flex w-full">
          <BaseButton
            type="button"
            className="flex items-center ml-auto px-3 py-2"
            onClick={() => setEditMode(true)}
          >
            <i className="fad fa-cloud-upload mr-2" aria-hidden="true" />
            Add Deployment
          </BaseButton>
        </div>
      )}
      {editMode && (
        <ContractDetailsDeploymentsAdd
          contract={contract}
          onStartDeployment={handleStartDeployment}
          onSaveClick={() => {
            setEditMode(false);
            onDeploymentAdd();
          }}
          onCancelClick={() => setEditMode(false)}
        />
      )}
    </>
  );
};

export default ContractDetailsDeployments;

import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { addApproval } from "../../../../services/approval";
import { getExtractionRightStatus } from "../../../../services/extractionRights";
import { formatDate } from "../../../../utils/formatDate";
import { formatKilolitres } from "../../../../utils/formatKilolitres";
import BaseButton from "../../../button/BaseButton";
import SecondaryButton from "../../../button/SecondaryButton";
import ErrorMessage from "../../../form/ErrorMessage";
import DescriptionList from "../../../layout/DescriptionList";
import Divider from "../../../layout/Divider";

interface IExtractionRightDetailsShowProps {
  extractionRight: any;
  onEditClick: () => void;
  onApproveClick: () => void;
}

const ExtractionRightDetailsShow: React.FunctionComponent<
  IExtractionRightDetailsShowProps
> = ({ extractionRight, onEditClick, onApproveClick }) => {
  const {
    mutateAsync: addExtractionRightApproval,
    isLoading: isMining,
    error,
  } = useMutation<any, any>((extractionRightData) =>
    addApproval(extractionRightData)
  );

  const handleApproveExtractionRight = async () => {
    await addExtractionRightApproval(extractionRight);

    onApproveClick();
  };

  const formatRemoteAddress = (remoteAddress: any) => {
    if (isMining === true) {
      remoteAddress = (
        <>
          <i className="fal fa-spinner fa-spin mr-2"></i>
          Mining blockchain transaction. This may take up to a minute.
        </>
      );
    }

    return remoteAddress;
  };

  const formatMigratedAt = (migratedAt: any) => {
    if (isMining === true) {
      migratedAt = "Currently migrating";
    }

    return migratedAt;
  };

  return (
    <>
      {error && (
        <ErrorMessage
          message="Blockchain transaction failed"
          details={[
            error?.response?.data?.errors?.[0]?.message ||
              error?.response?.data?.message,
          ]}
        />
      )}
      <DescriptionList
        fields={[
          { title: "Extraction Right Number", name: "extractionRight" },
          { title: "Remote Address", name: "remoteAddress" },
          { title: "Status", name: "statusName" },
          { title: "Migrated", name: "migratedAt" },
          { title: "Created", name: "createdAt" },
          { title: "Updated", name: "updatedAt" },
        ]}
        data={{
          ...extractionRight,
          remoteAddress: formatRemoteAddress(
            extractionRight?.remoteAddress || "-"
          ),
          migratedAt: formatMigratedAt(
            formatDate(extractionRight?.migratedAt, "d MMMM yyyy @ hh:mm a") ||
              "Not Yet Migrated"
          ),
          statusName: getExtractionRightStatus(extractionRight?.status),
          createdAt: formatDate(
            extractionRight?.createdAt,
            "d MMMM yyyy @ hh:mm a"
          ),
          updatedAt: formatDate(
            extractionRight?.updatedAt,
            "d MMMM yyyy @ hh:mm a"
          ),
          deployments: extractionRight?.deployments?.map((dp: any) => ({
            ...dp,
            address: dp.details.address,
          })),
          level0Resources: extractionRight?.level0Resources?.map(
            (level0Resource: any) => ({
              ...level0Resource,
              totalSupply: formatKilolitres(level0Resource.totalSupply),
              minSupply: formatKilolitres(level0Resource.minSupply),
              maxSupply: formatKilolitres(level0Resource.maxSupply),
            })
          ),
        }}
      />
      <div className="flex w-full gap-2 flex-row-reverse">
        <BaseButton
          type="button"
          className="flex items-center px-3 py-2"
          onClick={onEditClick}
        >
          <i className="fad fa-edit mr-2" aria-hidden="true" />
          Edit
        </BaseButton>
        {extractionRight?.status === 1 && (
          <SecondaryButton
            type="button"
            className="flex items-center px-3 py-2"
            onClick={handleApproveExtractionRight}
          >
            <i className="fad fa-check mr-2"></i>
            Approve Extraction Right
          </SecondaryButton>
        )}
      </div>
      <Divider />
      <h3 className="text-xl font-semibold">User Details</h3>
      <DescriptionList
        fields={[
          {
            title: "Name",
            name: "userName",
          },
          { title: "Email", name: "userEmail" },
        ]}
        data={{
          userName: (
            <Link
              to={`/manage/users/${extractionRight?.user.id}`}
              className="underline hover:text-gray-500"
            >
              {extractionRight?.user.name}
            </Link>
          ),
          userEmail: extractionRight?.user.email,
        }}
      />
    </>
  );
};

export default ExtractionRightDetailsShow;

import React from "react";
import { Routes, Route } from "react-router-dom";

import Dashboard from "./pages/dashboard/Dashboard";
import Manage from "./pages/manage/Manage";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Orders from "./pages/manage/orders/Orders";
import Level1Resources from "./pages/manage/level1Resources/Level1Resources";
import Contracts from "./pages/manage/contracts/Contracts";
import Liabilities from "./pages/manage/liabilities/Liabilities";
import ExtractionRights from "./pages/manage/extractionRights/ExtractionRights";
import ExtractionRightAdd from "./pages/manage/extractionRights/ExtractionRightAdd";
import Trades from "./pages/manage/trades/Trades";
import Users from "./pages/manage/users/Users";
import AppContext from "../context/AppContext";
import OrderDetails from "./pages/manage/orders/OrderDetails";
import Level1ResourceDetails from "./pages/manage/level1Resources/Level1ResourceDetails";
import Level1ResourceAdd from "./pages/manage/level1Resources/Level1ResourceAdd";
import ContractDetails from "./pages/manage/contracts/ContractDetails";
import LiabilityDetails from "./pages/manage/liabilities/LiabilityDetails";
import TradeDetails from "./pages/manage/trades/TradeDetails";
import ExtractionRightDetails from "./pages/manage/extractionRights/ExtractionRightDetails";
import UserDetails from "./pages/manage/users/UserDetails";
import UserAdd from "./pages/manage/users/UserAdd";

const App: React.FunctionComponent = () => {
  return (
    <AppContext>
      <Routes>
        <Route path="manage" element={<Manage />}>
          <Route path="orders" element={<Orders />} />
          <Route path="orders/:id" element={<OrderDetails />} />
          <Route path="level1resources" element={<Level1Resources />} />
          <Route path="level1resources/new" element={<Level1ResourceAdd />} />
          <Route
            path="level1resources/:id"
            element={<Level1ResourceDetails />}
          />
          <Route path="contracts" element={<Contracts />} />
          <Route path="contracts/:id" element={<ContractDetails />} />
          <Route path="extractionRights" element={<ExtractionRights />} />
          <Route path="extractionRights/new" element={<ExtractionRightAdd />} />
          <Route
            path="extractionRights/:id"
            element={<ExtractionRightDetails />}
          />
          <Route path="liabilities" element={<Liabilities />} />
          <Route path="liabilities/:id" element={<LiabilityDetails />} />
          <Route path="trades" element={<Trades />} />
          <Route path="trades/:id" element={<TradeDetails />} />
          <Route path="users" element={<Users />} />
          <Route path="users/new" element={<UserAdd />} />
          <Route path="users/:id" element={<UserDetails />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </AppContext>
  );
};

export default App;

import { useState } from "react";
import { useMutation } from "react-query";
import { addLevel0Resource } from "../../../../services/level0Resource";

import BaseButton from "../../../button/BaseButton";
import PrimaryButton from "../../../button/PrimaryButton";
import ErrorMessage from "../../../form/ErrorMessage";
import TextInput from "../../../form/TextInput";
import Card from "../../../layout/Card";

interface ILevel1ResourceDetailsLevel0ResourcesAddProps {
  level1Resource: any;
  onSaveClick: () => void;
  onCancelClick: () => void;
}

const Level1ResourceDetailsLevel0ResourcesAdd: React.FunctionComponent<
  ILevel1ResourceDetailsLevel0ResourcesAddProps
> = ({ level1Resource, onSaveClick, onCancelClick }) => {
  const { mutateAsync: addLevel1ResourceLevel0Resource, error } = useMutation<
    any,
    any
  >((level0ResourceData) => addLevel0Resource(level0ResourceData));
  const [newLevel0Resource, setNewLevel0Resource] = useState({
    level1ResourceId: level1Resource.id,
  } as any);

  const handleInputChange = (field: string, value: any) => {
    setNewLevel0Resource({
      ...newLevel0Resource,
      [field]: value,
    });
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    await addLevel1ResourceLevel0Resource({
      ...newLevel0Resource,
      totalSupply: newLevel0Resource.totalSupply * 1000,
      minSupply: newLevel0Resource.minSupply * 1000,
      maxSupply: newLevel0Resource.maxSupply * 1000,
    });

    onSaveClick();
  };

  return (
    <Card header="Add Level 0 WRS Details" className="bg-gray-50">
      <form className="mt-2 space-y-8" onSubmit={handleSave}>
        <div className="space-y-8 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            {error && (
              <ErrorMessage
                message="Save failed"
                details={[
                  error?.response?.data?.errors?.[0]?.message ||
                    error?.response?.data?.message,
                ]}
              />
            )}

            <div className="space-y-3 sm:space-y-2">
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="name"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="name"
                    value={newLevel0Resource.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="identifier"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Short Name
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="identifier"
                    value={newLevel0Resource.shortName}
                    onChange={(e) =>
                      handleInputChange("shortName", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="image"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Long Name
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="image"
                    value={newLevel0Resource.longName}
                    onChange={(e) =>
                      handleInputChange("longName", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="identifier"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Identifier
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="identifier"
                    value={newLevel0Resource.identifier}
                    onChange={(e) =>
                      handleInputChange("identifier", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-6 sm:gap-1 sm:items-start">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="totalSupply"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Supply (ML)
                  </label>
                  <div className="mt-1">
                    <TextInput
                      id="totalSupply"
                      type="number"
                      value={newLevel0Resource.totalSupply}
                      onChange={(e) =>
                        handleInputChange("totalSupply", Number(e.target.value))
                      }
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="minSupply"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Min (ML)
                  </label>
                  <div className="mt-1">
                    <TextInput
                      id="minSupply"
                      type="number"
                      value={newLevel0Resource.minSupply}
                      onChange={(e) =>
                        handleInputChange("minSupply", Number(e.target.value))
                      }
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Max (ML)
                  </label>
                  <div className="mt-1">
                    <TextInput
                      id="maxSupply"
                      type="number"
                      value={newLevel0Resource.maxSupply}
                      onChange={(e) =>
                        handleInputChange("maxSupply", Number(e.target.value))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-2 justify-end">
          <BaseButton
            className="flex items-center px-3 py-2"
            type="button"
            onClick={onCancelClick}
          >
            <i className="fal fa-times mr-2" />
            Cancel
          </BaseButton>
          <PrimaryButton className="flex items-center  px-3 py-2" type="submit">
            <i className="fad fa-cloud-upload mr-2" />
            Save Level 0 WRS
          </PrimaryButton>
        </div>
      </form>
    </Card>
  );
};

export default Level1ResourceDetailsLevel0ResourcesAdd;

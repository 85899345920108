import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getPendingExtractionRight } from "../../../services/extractionRights";
import Card from "../../layout/Card";
import Table from "../../layout/Table";

const PendingExtractionRight: React.FunctionComponent = () => {
  const { data: extractionRights } = useQuery(
    ["extractionRights", { pending: true }],
    getPendingExtractionRight,
    { keepPreviousData: true }
  );

  return (
    <Card header="Extraction Rights Pending Approval" className="w-1/2">
      <Table
        fields={[
          { title: "Extraction Right Number", name: "extractionRight" },
          { title: "Name", name: "userName" },
        ]}
        data={extractionRights?.map((lic: any) => ({
          ...lic,
          extractionRight: (
            <Link
              to={`/manage/extractionRights/${lic.id}`}
              className="underline text-blue-700 hover:text-blue-500"
            >
              {lic.extractionRight}
            </Link>
          ),
          userName: lic.user?.name,
        }))}
        noRowsText="There are no pending users"
      />
    </Card>
  );
};

export default PendingExtractionRight;

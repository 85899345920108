import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

const PrimaryButton: React.FunctionComponent<
  ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, className, ...props }) => {
  return (
    <button
      className={classNames(
        "inline-flex items-center px-6 py-3 border border-blue-700 text-base font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;

import api from "./api";

export const getAllContracts = async (): Promise<any> => {
  const { data } = await api.get("/admin/contracts");

  return data.contracts;
};

export const getContract = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/contracts/${id}`);

  return data.contract;
};

export const saveContract = async (contract: any): Promise<any> => {
  const { data } = await api.put(`/admin/contracts/${contract.id}`, contract);

  return data.contract;
};

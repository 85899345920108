import { useState } from "react";
import { useMutation } from "react-query";

import BaseButton from "../../../button/BaseButton";
import PrimaryButton from "../../../button/PrimaryButton";
import TextInput from "../../../form/TextInput";

import ErrorMessage from "../../../form/ErrorMessage";
import { saveContract } from "../../../../services/contract";
import TextArea from "../../../form/TextArea";

interface IContractDetailsEditProps {
  contract: any;
  onSaveClick: () => void;
  onCancelClick: () => void;
}

const ContractDetailsEdit: React.FunctionComponent<
  IContractDetailsEditProps
> = ({ contract, onSaveClick, onCancelClick }) => {
  const { mutateAsync: saveContractData, error } = useMutation<any, any>(
    (contractData) => saveContract(contractData)
  );
  const [currentContract, setCurrentContract] = useState(contract);

  const handleInputChange = (field: string, value: any) => {
    setCurrentContract({
      ...currentContract,
      [field]: value,
    });
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    await saveContractData(currentContract);

    onSaveClick();
  };

  return (
    <form className="space-y-8" onSubmit={handleSave}>
      <div className="space-y-8 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          {error && (
            <ErrorMessage
              message="Save failed"
              details={[
                error?.response?.data?.errors?.[0]?.message ||
                  error?.response?.data?.message,
              ]}
            />
          )}

          <div className="space-y-3 sm:space-y-2">
            <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
              <label
                htmlFor="contractName"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Name
              </label>
              <div className="sm:col-span-3">
                <TextInput
                  id="contractName"
                  value={currentContract.contractName}
                  onChange={(e) =>
                    handleInputChange("contractName", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
              <label
                htmlFor="identifier"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Identifier
              </label>
              <div className="sm:col-span-3">
                <TextInput
                  id="identifier"
                  value={currentContract.identifier}
                  onChange={(e) =>
                    handleInputChange("identifier", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
              <label
                htmlFor="image"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Description
              </label>
              <div className="sm:col-span-3">
                <TextInput
                  id="image"
                  value={currentContract.description}
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
              <label
                htmlFor="abi"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                ABI
              </label>
              <div className="sm:col-span-3">
                <TextArea
                  id="abi"
                  value={currentContract.abi}
                  rows={10}
                  onChange={(e) => handleInputChange("abi", e.target.value)}
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
              <label
                htmlFor="bytecode"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                ByteCode
              </label>
              <div className="sm:col-span-3">
                <TextArea
                  id="bytecode"
                  value={currentContract.bytecode}
                  rows={10}
                  onChange={(e) =>
                    handleInputChange("bytecode", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-2 justify-end">
        <BaseButton
          className="flex items-center px-3 py-2"
          type="button"
          onClick={onCancelClick}
        >
          <i className="fal fa-times mr-2" />
          Cancel
        </BaseButton>
        <PrimaryButton className="flex items-center  px-3 py-2" type="submit">
          <i className="fad fa-cloud-upload mr-2" />
          Save
        </PrimaryButton>
      </div>
    </form>
  );
};

export default ContractDetailsEdit;

import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";

import Divider from "../../../layout/Divider";

import ExtractionRightDetailsShow from "./ExtractionRightDetailsShow";
import ExtractionRightDetailsEdit from "./ExtractionRightDetailsEdit";
import ExtractionRightDetailsAccounts from "./ExtractionRightDetailsAccounts";
import { getExtractionRight } from "../../../../services/extractionRights";

const ExtractionRightDetails: React.FunctionComponent = () => {
  const { id } = useParams();
  const { data: extractionRight, refetch: fetchExtractionRightData } = useQuery(
    ["extractionRights", { id }],
    () => id && getExtractionRight(id)
  );
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="flex flex-col gap-4 w-full">
      <h3 className="text-2xl font-semibold">Extraction Right</h3>
      {editMode ? (
        <ExtractionRightDetailsEdit
          extractionRight={extractionRight}
          onSaveClick={() => {
            setEditMode(false);
            fetchExtractionRightData();
          }}
          onCancelClick={() => setEditMode(false)}
        />
      ) : (
        <ExtractionRightDetailsShow
          extractionRight={extractionRight}
          onEditClick={() => setEditMode(true)}
          onApproveClick={() => fetchExtractionRightData()}
        />
      )}
      <Divider />
      <ExtractionRightDetailsAccounts
        extractionRight={extractionRight}
        onLevel0ResourceAdded={() => fetchExtractionRightData()}
      />
    </div>
  );
};

export default ExtractionRightDetails;

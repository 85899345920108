import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";

import UserDetailsShow from "./UserDetailsShow";
import UserDetailsEdit from "./UserDetailsEdit";
import { getUser } from "../../../../services/user";

const UserDetails: React.FunctionComponent = () => {
  const { id } = useParams();
  const { data: user, refetch: fecthUserData } = useQuery(
    ["users", { id }],
    () => id && getUser(id),
    { keepPreviousData: true }
  );
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="flex flex-col gap-4 w-full">
      <h3 className="text-2xl font-semibold">{user?.name}</h3>
      {editMode ? (
        <UserDetailsEdit
          user={user}
          onSaveClick={() => {
            setEditMode(false);
            fecthUserData();
          }}
          onCancelClick={() => setEditMode(false)}
        />
      ) : (
        <UserDetailsShow user={user} onEditClick={() => setEditMode(true)} />
      )}
    </div>
  );
};

export default UserDetails;

import { useState } from "react";
import { formatKilolitres } from "../../../../utils/formatKilolitres";
import BaseButton from "../../../button/BaseButton";
import Table from "../../../layout/Table";
import Level1ResourceDetailsLevel0ResourcesAdd from "./Level1ResourceDetailsLevel0ResourcesAdd";

interface ILevel1ResourceDetailsLevel0ResourcesProps {
  level1Resource: any;
  onLevel0ResourceAdded: () => void;
}

const Level1ResourceDetailsLevel0Resources: React.FunctionComponent<
  ILevel1ResourceDetailsLevel0ResourcesProps
> = ({ level1Resource, onLevel0ResourceAdded }) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <>
      <h3 className="text-xl font-semibold">Level 0 WRS</h3>
      <Table
        fields={[
          { title: "ID", name: "identifier" },
          { title: "Name", name: "name" },
          { title: "Total Supply", name: "totalSupply" },
          { title: "Min. Supply", name: "minSupply" },
          { title: "Max. Supply", name: "maxSupply" },
        ]}
        data={level1Resource?.level0Resources?.map((level0Resource: any) => ({
          ...level0Resource,
          totalSupply: formatKilolitres(level0Resource.totalSupply),
          minSupply: formatKilolitres(level0Resource.minSupply),
          maxSupply: formatKilolitres(level0Resource.maxSupply),
        }))}
        noRowsText="No level0Resources added"
      />
      {!editMode && (
        <div className="flex w-full">
          <BaseButton
            type="button"
            className="flex items-center ml-auto px-3 py-2"
            onClick={() => setEditMode(true)}
          >
            <i className="fad fa-cloud-upload mr-2" aria-hidden="true" />
            Add Level 0 WRS
          </BaseButton>
        </div>
      )}
      {editMode && (
        <Level1ResourceDetailsLevel0ResourcesAdd
          level1Resource={level1Resource}
          onSaveClick={() => {
            setEditMode(false);
            onLevel0ResourceAdded();
          }}
          onCancelClick={() => setEditMode(false)}
        />
      )}
    </>
  );
};

export default Level1ResourceDetailsLevel0Resources;

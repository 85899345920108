import { Link } from "react-router-dom";
import { formatDate } from "../../../../utils/formatDate";
import BaseButton from "../../../button/BaseButton";
import DescriptionList from "../../../layout/DescriptionList";

interface IUserDetailsShowProps {
  user: any;
  onEditClick: () => void;
}

const UserDetailsShow: React.FunctionComponent<IUserDetailsShowProps> = ({
  user,
  onEditClick,
}) => {
  return (
    <>
      <DescriptionList
        className="overflow-auto"
        fields={[
          { title: "Name", name: "name" },
          { title: "Email", name: "email" },
          { title: "Admin User", name: "isAdmin" },
          {
            title: "ExtractionRight",
            name: "extractionRightId",
            hidden: !user?.extractionRight,
          },
          { title: "Created", name: "createdAt" },
          { title: "Updated", name: "updatedAt" },
        ]}
        data={{
          ...user,
          isAdmin: String(user?.isAdmin),
          extractionRightId: (
            <Link
              to={`/manage/extractionRights/${user?.extractionRight?.id}`}
              className="underline hover:text-gray-500"
            >
              {user?.extractionRight?.id}
            </Link>
          ),
          createdAt: formatDate(user?.createdAt, "d MMMM yyyy @ hh:mm a"),
          updatedAt: formatDate(user?.updatedAt, "d MMMM yyyy @ hh:mm a"),
        }}
      />
      <div className="flex w-full">
        <BaseButton
          type="button"
          className="flex items-center ml-auto px-3 py-2"
          onClick={onEditClick}
        >
          <i className="fad fa-edit mr-2" aria-hidden="true" />
          Edit
        </BaseButton>
      </div>
    </>
  );
};

export default UserDetailsShow;

import React from "react";
import classNames from "classnames";

interface ICardProps {
  children: React.ReactNode;
  className?: string;
  header?: React.ReactNode;
}

const Card: React.FunctionComponent<ICardProps> = ({
  children,
  className,
  header,
}) => {
  return (
    <div
      className={classNames(className, "border border-gray-300 rounded-md p-4")}
    >
      {header && (
        <div className="flex items-center justify-between border-b pb-3">
          <div className="w-full my-2 text-center font-bold text-gray-900">
            {header}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;

import { useState } from "react";
import { useMutation } from "react-query";

import BaseButton from "../../../button/BaseButton";
import PrimaryButton from "../../../button/PrimaryButton";
import TextInput from "../../../form/TextInput";

import ErrorMessage from "../../../form/ErrorMessage";
import {
  getExtractionRightStatus,
  saveExtractionRight,
} from "../../../../services/extractionRights";

interface IExtractionRightDetailsEditProps {
  extractionRight: any;
  onSaveClick: () => void;
  onCancelClick: () => void;
}

const ExtractionRightDetailsEdit: React.FunctionComponent<
  IExtractionRightDetailsEditProps
> = ({ extractionRight, onSaveClick, onCancelClick }) => {
  const { mutateAsync: saveExtractionRightData, error } = useMutation<any, any>(
    (extractionRightData) => saveExtractionRight(extractionRightData)
  );
  const [currentExtractionRight, setCurrentExtractionRight] =
    useState(extractionRight);

  const handleInputChange = (field: string, value: any) => {
    setCurrentExtractionRight({
      ...currentExtractionRight,
      [field]: value,
    });
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    delete currentExtractionRight.statusName;

    await saveExtractionRightData(currentExtractionRight);

    onSaveClick();
  };

  return (
    <form className="space-y-8" onSubmit={handleSave}>
      <div className="space-y-8 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          {error && (
            <ErrorMessage
              message="Save failed"
              details={[
                error?.response?.data?.errors?.[0]?.message ||
                  error?.response?.data?.message,
              ]}
            />
          )}

          <div className="space-y-3 sm:space-y-2">
            <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
              <label
                htmlFor="extractionRight"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Extraction Right
              </label>
              <div className="sm:col-span-3">
                <TextInput
                  id="extractionRight"
                  value={currentExtractionRight.extractionRight}
                  onChange={(e) =>
                    handleInputChange("extractionRight", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
              <label
                htmlFor="remoteAddress"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Remote Address
              </label>
              <div className="sm:col-span-3">
                <TextInput
                  id="remoteAddress"
                  value={currentExtractionRight.remoteAddress}
                  disabled
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
              <label
                htmlFor="statusName"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Status
              </label>
              <div className="sm:col-span-3">
                <TextInput
                  id="statusName"
                  value={getExtractionRightStatus(
                    currentExtractionRight.status
                  )}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-2 justify-end">
        <BaseButton
          className="flex items-center px-3 py-2"
          type="button"
          onClick={onCancelClick}
        >
          <i className="fal fa-times mr-2" />
          Cancel
        </BaseButton>
        <PrimaryButton className="flex items-center  px-3 py-2" type="submit">
          <i className="fad fa-cloud-upload mr-2" />
          Save
        </PrimaryButton>
      </div>
    </form>
  );
};

export default ExtractionRightDetailsEdit;

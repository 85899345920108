import api from "./api";

export const logIn = async (loginDetails: {
  email: string;
  password: string;
}): Promise<{ user: any; token: string }> => {
  const { data } = await api.post("/admin/login", loginDetails);

  return data;
};

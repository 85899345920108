import api from "./api";

export const getAllUsers = async (): Promise<any> => {
  const { data } = await api.get("/admin/users");

  return data.users;
};

export const getUser = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/users/${id}`);

  return data.user;
};

export const addUser = async (user: any): Promise<any> => {
  const { data } = await api.post("/admin/users", user);

  return data.user;
};

export const saveUser = async (user: any): Promise<any> => {
  const { data } = await api.put(`/admin/users/${user.id}`, user);

  return data.user;
};

import { formatDate } from "../../../../utils/formatDate";
import BaseButton from "../../../button/BaseButton";
import DescriptionList from "../../../layout/DescriptionList";

interface IContractDetailsShowProps {
  contract: any;
  onEditClick: () => void;
}

const ContractDetailsShow: React.FunctionComponent<
  IContractDetailsShowProps
> = ({ contract, onEditClick }) => {
  return (
    <>
      <DescriptionList
        className="overflow-auto"
        fields={[
          { title: "Name", name: "contractName" },
          { title: "Identifier", name: "identifier" },
          { title: "Description", name: "description" },
          { title: "ABI", name: "abi", code: true },
          { title: "ByteCode", name: "bytecode", code: true },
          { title: "Created", name: "createdAt" },
          { title: "Updated", name: "updatedAt" },
        ]}
        data={{
          ...contract,
          createdAt: formatDate(contract?.createdAt, "d MMMM yyyy @ hh:mm a"),
          updatedAt: formatDate(contract?.updatedAt, "d MMMM yyyy @ hh:mm a"),
        }}
      />
      <div className="flex w-full">
        <BaseButton
          type="button"
          className="flex items-center ml-auto px-3 py-2"
          onClick={onEditClick}
        >
          <i className="fad fa-edit mr-2" aria-hidden="true" />
          Edit
        </BaseButton>
      </div>
    </>
  );
};

export default ContractDetailsShow;

import api from "./api";

export const liabilityStatuses = [
  { id: 0, name: "New" },
  { id: 1, name: "Paid" },
  { id: 2, name: "Confirmed " },
  { id: 3, name: "Closed" },
];

export const getAllLiabilities = async (): Promise<any> => {
  const { data } = await api.get("/admin/liabilities");

  return data.liabilities;
};

export const getLiability = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/liabilities/${id}`);

  return data.liability;
};

export const getLiabilityStatus = (status: number): any => {
  return liabilityStatuses.find((s) => s.id === status)?.name;
};

import { getPlatformName } from "../../../../services/level1Resource";
import { formatDate } from "../../../../utils/formatDate";
import BaseButton from "../../../button/BaseButton";
import DescriptionList from "../../../layout/DescriptionList";
import Table from "../../../layout/Table";

interface ILevel1ResourceDetailsShowProps {
  level1Resource: any;
  onEditClick: () => void;
}

const Level1ResourceDetailsShow: React.FunctionComponent<
  ILevel1ResourceDetailsShowProps
> = ({ level1Resource, onEditClick }) => {
  return (
    <>
      <DescriptionList
        fields={[
          { title: "Name", name: "name" },
          { title: "Identifier", name: "identifier" },
          { title: "Platform", name: "platformName" },
          { title: "Image", name: "image" },
          { title: "Created", name: "createdAt" },
          { title: "Updated", name: "updatedAt" },
        ]}
        data={{
          ...level1Resource,
          platformName: getPlatformName(level1Resource?.platform),
          createdAt: formatDate(
            level1Resource?.createdAt,
            "d MMMM yyyy @ hh:mm a"
          ),
          updatedAt: formatDate(
            level1Resource?.updatedAt,
            "d MMMM yyyy @ hh:mm a"
          ),
        }}
      />
      <Table
        fields={[
          { title: "Contract", name: "contractName" },
          { title: "Address", name: "address" },
          { title: "Description", name: "description" },
        ]}
        data={level1Resource?.deployments?.map((dp: any) => ({
          ...dp,
          address: dp.details.address,
        }))}
        noRowsText="No deployments found"
      />
      <div className="flex w-full">
        <BaseButton
          type="button"
          className="flex items-center ml-auto px-3 py-2"
          onClick={onEditClick}
        >
          <i className="fad fa-edit mr-2" aria-hidden="true" />
          Edit
        </BaseButton>
      </div>
    </>
  );
};

export default Level1ResourceDetailsShow;

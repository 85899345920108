import api from "./api";

export const addDeployment = async (deployment: any): Promise<any> => {
  const { data } = await api.post("/admin/deployments", deployment);

  return data.deployment;
};

export const deleteDeployment = async (id: string): Promise<void> => {
  await api.delete(`/admin/deployments/${id}`);
};

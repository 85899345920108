import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

const BaseButton: React.FunctionComponent<
  ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, className, ...props }) => {
  return (
    <button
      className={classNames(
        "inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default BaseButton;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

import LogoSVG from "../../images/logo.svg";
import { useAppContext } from "../../context/AppContext";

const MainHeader: React.FunctionComponent = () => {
  const location = useLocation();
  const { loggedInInfo } = useAppContext();

  const isActivePath = (path: string): boolean => {
    return location.pathname.startsWith(path);
  };

  return (
    <nav className="navbar w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <div className="flex">
                <img className="h-8 w-8" src={LogoSVG} alt="Workflow" />
                <h1 className="text-white text-xl pt-1 pl-2 font-bold hidden md:block">
                  Water Ledger Admin
                </h1>
              </div>
            </div>
            {loggedInInfo?.isLoggedIn && (
              <div className="ml-10 flex items-baseline space-x-4">
                <Link
                  to="/"
                  className={classNames(
                    {
                      "bg-gray-900 text-white": !isActivePath("/manage"),
                      "text-gray-300 hover:bg-gray-700 hover:text-white":
                        isActivePath("/manage"),
                    },
                    "px-3 py-2 rounded-md font-medium"
                  )}
                >
                  Home
                </Link>
                <Link
                  to="/manage"
                  className={classNames(
                    {
                      "bg-gray-900 text-white": isActivePath("/manage"),
                      "text-gray-300 hover:bg-gray-700 hover:text-white":
                        !isActivePath("/manage"),
                    },
                    "px-3 py-2 rounded-md font-medium"
                  )}
                >
                  Manage
                </Link>
              </div>
            )}
          </div>
          <div className="ml-4 md:ml-6">
            {loggedInInfo?.isLoggedIn ? (
              <div className="flex text-gray-400">
                <span className="hidden md:block">
                  Logged in as {loggedInInfo.userDetails?.name}
                </span>
                <span className="hidden md:block mx-1"> | </span>
                <Link
                  to="/logout"
                  className="hover:text-white flex items-center"
                >
                  <span className="mr-2"> Log Out</span>
                  <i className="fal fa-sign-in fa-lg" />
                </Link>
              </div>
            ) : (
              <Link
                to="/login"
                className="text-gray-400 hover:text-white flex items-center"
              >
                <span className="mr-2">Log In</span>
                <i className="fal fa-sign-in fa-lg" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MainHeader;

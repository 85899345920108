import { useQuery } from "react-query";

import { getAllOrders } from "../../../../services/order";
import { formatDate } from "../../../../utils/formatDate";
import { formatKilolitres } from "../../../../utils/formatKilolitres";
import Table from "../../../layout/Table";

const Orders: React.FunctionComponent = () => {
  const { data: orders } = useQuery("orders", getAllOrders, {
    keepPreviousData: true,
  });

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-semibold">Manage Orders</h3>
      <p className="text-md text-gray-500">
        List all orders within the Water Ledger database. Note that it is not
        possible to add or edit, as this must be done through the smart
        contracts.
      </p>
      <Table
        fields={[
          { title: "ID", name: "id", linkTo: "/manage/orders/:id" },
          { title: "Type", name: "type" },
          { title: "Price (/ML)", name: "price" },
          { title: "Quantity", name: "quantity" },
          { title: "Date", name: "createdAt" },
        ]}
        data={orders?.map((order: any) => {
          const updatedOrder = { ...order };

          updatedOrder.price = `$${order.price}`;
          updatedOrder.quantity = formatKilolitres(order.quantity);
          updatedOrder.createdAt = formatDate(order.createdAt, "d MMMM yyyy");

          return updatedOrder;
        })}
        noRowsText="No Orders Data"
      />
    </div>
  );
};

export default Orders;

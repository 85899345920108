import classNames from "classnames";
import { Link, generatePath } from "react-router-dom";

interface ITableProps {
  containerClassName?: string;
  className?: string;
  fields: Array<{
    title: string;
    name: string;
    linkTo?: string;
  }>;
  data: Array<Record<string, any>>;
  noRowsText?: string;
}

const Table: React.FunctionComponent<ITableProps> = ({
  containerClassName,
  className,
  fields,
  data,
  noRowsText,
}) => {
  return (
    <div
      className={classNames(
        "overflow-auto ring-1 ring-black ring-opacity-10 md:rounded-lg",
        containerClassName
      )}
    >
      <table
        className={classNames(
          "table-auto min-w-full divide-y divide-gray-300",
          className
        )}
      >
        <thead className="bg-gray-50">
          <tr>
            {fields?.map((th, i) => (
              <th
                key={`${th.name}:${i}`}
                scope="col"
                className="whitespace-nowrap py-2 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-6"
              >
                {th.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {data?.length === 0 && noRowsText ? (
            <tr>
              <td
                colSpan={fields.length}
                className="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6 text-gray-500"
              >
                {noRowsText}
              </td>
            </tr>
          ) : (
            data?.map((td, i) => (
              <tr key={i}>
                {fields.map((field, j) => (
                  <td
                    key={`${i}_${j}`}
                    className="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-6"
                  >
                    {field.linkTo ? (
                      <Link
                        to={generatePath(field.linkTo, td)}
                        className="text-indigo-700 underline"
                      >
                        {td[field.name]}
                      </Link>
                    ) : (
                      td[field.name]
                    )}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

import { useParams, Link } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { formatKilolitres } from "../../../../utils/formatKilolitres";
import { formatDate } from "../../../../utils/formatDate";
import Divider from "../../../layout/Divider";
import DescriptionList from "../../../layout/DescriptionList";
import { getTrade, getTradeStatus } from "../../../../services/trade";
import SecondaryButton from "../../../button/SecondaryButton";
import ErrorMessage from "../../../form/ErrorMessage";
import { addTradeApproval as addApproval } from "../../../../services/tradeApproval";

const TradeDetails: React.FunctionComponent = () => {
  const { id } = useParams();
  const { data: trade, refetch } = useQuery(
    ["trades", { id }],
    () => id && getTrade(id),
    { keepPreviousData: true }
  );
  const {
    mutateAsync: addTradeApproval,
    isLoading: isApproving,
    error,
  } = useMutation<any, any>((tradeData) => addApproval(tradeData));

  const handleApproveTrade = async () => {
    await addTradeApproval(trade);

    refetch();
  };

  return (
    <div className="flex flex-col gap-4 w-full">
      <h3 className="text-2xl font-semibold">View Trade Details</h3>
      {error && (
        <ErrorMessage
          message="Trade approval failed"
          details={[
            error?.response?.data?.errors?.[0]?.message ||
              error?.response?.data?.message,
          ]}
        />
      )}
      <DescriptionList
        fields={[
          { title: "ID", name: "id" },
          {
            title: "Order ID",
            name: "orderId",
          },
          { title: "Status", name: "status" },
          { title: "Price", name: "price" },
          { title: "Quantity", name: "quantity" },
          { title: "From Level 0 WRS", name: "sellerLevel0ResourceName" },
          { title: "To Level 0 WRS", name: "buyerLevel0ResourceName" },
          { title: "Created", name: "createdAt" },
          { title: "Struck", name: "struckAt" },
        ]}
        data={{
          ...trade,
          orderId: (
            <Link
              to={`/manage/orders/${trade?.order.id}`}
              className="underline hover:text-gray-500"
            >
              {trade?.order.id}
            </Link>
          ),
          status: isApproving ? (
            <i className="fal fa-spinner fa-spin mr-2" />
          ) : (
            getTradeStatus(trade?.status)
          ),
          price: `$${trade?.price}`,
          quantity: formatKilolitres(trade?.quantity),
          sellerLevel0ResourceName: trade?.sellerAccount.level0Resource.name,
          buyerLevel0ResourceName: trade?.buyerAccount.level0Resource.name,
          createdAt: formatDate(trade?.createdAt, "d MMMM yyyy @ hh:mm a"),
          struckAt:
            formatDate(trade?.struckAt, "d MMMM yyyy @ hh:mm a") ||
            "Not Struck",
        }}
      />
      {trade?.status === 0 && (
        <div className="flex w-full">
          <SecondaryButton
            type="button"
            className="flex items-center ml-auto px-3 py-2"
            onClick={handleApproveTrade}
          >
            <i className="fad fa-check mr-2"></i>
            Approve Trade
          </SecondaryButton>
        </div>
      )}
      <Divider />
      <h3 className="text-xl font-semibold">Blockchain</h3>
      <DescriptionList
        fields={[
          { title: "Remote Id", name: "remoteId" },
          { title: "Creation Hash", name: "creationTx" },
          {
            title: "From Account",
            name: "sellerExtractionRightRemoteAddress",
          },
          { title: "To Account", name: "buyerExtractionRightRemoteAddress" },
        ]}
        data={{
          ...trade,
          sellerExtractionRightRemoteAddress:
            trade?.sellerAccount.extractionRight.remoteAddress,
          buyerExtractionRightRemoteAddress:
            trade?.buyerAccount.extractionRight.remoteAddress,
        }}
      />
    </div>
  );
};

export default TradeDetails;

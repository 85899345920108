import api from "./api";

export const getAllOrders = async (): Promise<any> => {
  const { data } = await api.get("/admin/orders");

  return data.orders;
};

export const getOrder = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/orders/${id}`);

  return data.order;
};

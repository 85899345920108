import { useQuery } from "react-query";

import { getAllContracts } from "../../../../services/contract";

import Table from "../../../layout/Table";

const Contracts: React.FunctionComponent = () => {
  const { data: contracts } = useQuery("contracts", getAllContracts, {
    keepPreviousData: true,
  });

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-semibold">Manage Contracts</h3>
      <p className="text-md text-gray-500">
        Contract and deployment management
      </p>
      <Table
        fields={[
          { title: "ID", name: "id", linkTo: "/manage/contracts/:id" },
          { title: "Name", name: "contractName" },
          { title: "Deployments", name: "deploymentsCount" },
        ]}
        data={contracts?.map((c: any) => ({
          ...c,
          deploymentsCount: c.deployments.length,
        }))}
        noRowsText="No Contracts Data"
      />
    </div>
  );
};

export default Contracts;

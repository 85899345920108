import { useState } from "react";
import { useMutation, useQuery } from "react-query";

import { addAccount } from "../../../../services/account";
import { getAllLevel0Resources } from "../../../../services/level0Resource";

import BaseButton from "../../../button/BaseButton";
import PrimaryButton from "../../../button/PrimaryButton";
import ErrorMessage from "../../../form/ErrorMessage";
import Select from "../../../form/Select";
import TextInput from "../../../form/TextInput";
import Card from "../../../layout/Card";

interface IExtractionRightDetailsAccountsAddProps {
  extractionRight: any;
  onSaveClick: () => void;
  onCancelClick: () => void;
}

const ExtractionRightDetailsAccountsAdd: React.FunctionComponent<
  IExtractionRightDetailsAccountsAddProps
> = ({ extractionRight, onSaveClick, onCancelClick }) => {
  const { mutateAsync: addExtractionRightAccount, error } = useMutation<
    any,
    any
  >((accountData) => addAccount(accountData));
  const { data: level0Resources } = useQuery(
    "level0Resources",
    getAllLevel0Resources
  );

  const [newAccount, setNewAccount] = useState({
    extractionRightId: extractionRight.id,
  } as any);

  const handleInputChange = (field: string, value: any) => {
    setNewAccount({
      ...newAccount,
      [field]: value,
    });
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    await addExtractionRightAccount({
      ...newAccount,
    });

    onSaveClick();
  };

  return (
    <Card header="Add Account Details" className="bg-gray-50">
      <form className="mt-2 space-y-8" onSubmit={handleSave}>
        <div className="space-y-8 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            {error && (
              <ErrorMessage
                message="Save failed"
                details={[
                  error?.response?.data?.errors?.[0]?.message ||
                    error?.response?.data?.message,
                ]}
              />
            )}

            <div className="space-y-3 sm:space-y-2">
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="level0ResourceId"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Level 0 WRS
                </label>
                <div className="sm:col-span-3">
                  <Select
                    name="level0ResourceId"
                    value={newAccount.level0ResourceId}
                    onChange={(e) => {
                      handleInputChange("level0ResourceId", e.target.value);
                    }}
                  >
                    <option></option>
                    {level0Resources.map((s: any) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="waterAccount"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Water Account
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="waterAccount"
                    value={newAccount.waterAccount}
                    onChange={(e) =>
                      handleInputChange("waterAccount", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
                <label
                  htmlFor="allocation"
                  className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Allocation (in KL)
                </label>
                <div className="sm:col-span-3">
                  <TextInput
                    id="allocation"
                    type="number"
                    value={newAccount.allocation}
                    onChange={(e) =>
                      handleInputChange("allocation", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-2 justify-end">
          <BaseButton
            className="flex items-center px-3 py-2"
            type="button"
            onClick={onCancelClick}
          >
            <i className="fal fa-times mr-2" />
            Cancel
          </BaseButton>
          <PrimaryButton className="flex items-center  px-3 py-2" type="submit">
            <i className="fad fa-cloud-upload mr-2" />
            Save Account
          </PrimaryButton>
        </div>
      </form>
    </Card>
  );
};

export default ExtractionRightDetailsAccountsAdd;

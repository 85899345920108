import { format, parseISO } from "date-fns";

export const formatDate = (date: Date, formatString: string) => {
  if (!date) return null;

  if (typeof date === "string") {
    date = parseISO(date);
  }

  return format(date, formatString);
};

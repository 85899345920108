import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";

import Divider from "../../../layout/Divider";
import { getLevel1Resource } from "../../../../services/level1Resource";

import Level1ResourceDetailsShow from "./Level1ResourceDetailsShow";
import Level1ResourceDetailsEdit from "./Level1ResourceDetailsEdit";
import Level1ResourceDetailsZones from "./Level1ResourceDetailsZones";

const Level1ResourceDetails: React.FunctionComponent = () => {
  const { id } = useParams();
  const { data: level1Resource, refetch: fetchLevel1ResourceData } = useQuery(
    ["level1resources", { id }],
    () => id && getLevel1Resource(id)
  );
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="flex flex-col gap-4 w-full">
      <h3 className="text-2xl font-semibold">{level1Resource?.name}</h3>
      {editMode ? (
        <Level1ResourceDetailsEdit
          level1Resource={level1Resource}
          onSaveClick={() => {
            setEditMode(false);
            fetchLevel1ResourceData();
          }}
          onCancelClick={() => setEditMode(false)}
        />
      ) : (
        <Level1ResourceDetailsShow
          level1Resource={level1Resource}
          onEditClick={() => setEditMode(true)}
        />
      )}
      <Divider />
      <Level1ResourceDetailsZones
        level1Resource={level1Resource}
        onLevel0ResourceAdded={() => fetchLevel1ResourceData()}
      />
    </div>
  );
};

export default Level1ResourceDetails;

import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import classNames from "classnames";

import MainLayout from "../../layout/MainLayout";
import PageTitle from "../../layout/PageTitle";
import Divider from "../../layout/Divider";

const Dashboard: React.FunctionComponent = () => {
  const location = useLocation();

  const navItem = (name: string, url: string) => {
    const isActive = location.pathname.startsWith(url);

    return (
      <Link
        to={url}
        className={classNames(
          {
            "bg-gray-200 text-gray-900": isActive,
            "text-gray-600 hover:bg-gray-50 hover:text-gray-900": !isActive,
          },
          "flex items-center px-3 py-2 font-medium rounded-md"
        )}
        aria-current="page"
      >
        <span className="truncate">{name}</span>
      </Link>
    );
  };

  return (
    <MainLayout>
      <div className="w-full flex flex-col gap-10">
        <PageTitle>Manage Data</PageTitle>
        <div className="flex flex-col sm:flex-row w-full">
          <div className="px-2 sm:px-0 w-full sm:w-1/6">
            <nav className="space-y-1" aria-label="Sidebar">
              {navItem("Orders", "/manage/orders")}
              {navItem("Level 1 WRS", "/manage/level1resources")}
              {navItem("Contracts", "/manage/contracts")}
              {navItem("Extraction Rights", "/manage/extractionRights")}
              {navItem("Liabilities", "/manage/liabilities")}
              {navItem("Trades", "/manage/trades")}
              {navItem("Users", "/manage/users")}
            </nav>
          </div>
          <Divider className="block mx-2 sm:hidden" />
          <div className="py-2 px-2 sm:px-0 sm:pl-10 w-full sm:w-5/6">
            <Outlet />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Dashboard;

import api from "./api";

export const platforms = [
  { id: 0, name: "Not Found" },
  { id: 1, name: "Ethereum" },
  { id: 2, name: "Hashgraph" },
  { id: 3, name: "Daml" },
];

export const getAllLevel1Resources = async (): Promise<any> => {
  const { data } = await api.get("/admin/level1Resources");

  return data.level1Resources;
};

export const getLevel1Resource = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/level1Resources/${id}`);

  return data.level1Resource;
};

export const addLevel1Resource = async (level1Resource: any): Promise<any> => {
  const { data } = await api.post("/admin/level1Resources", level1Resource);

  return data.level1Resource;
};

export const saveLevel1Resource = async (level1Resource: any): Promise<any> => {
  const { data } = await api.put(
    `/admin/level1Resources/${level1Resource.id}`,
    level1Resource
  );

  return data.level1Resource;
};

export const getPlatformName = (platform: number): String => {
  return platforms[platform]?.name;
};

import axios from "axios";
import ENV from "../env";

const axiosInstance = axios.create({
  baseURL: ENV.API_HOST,
});

axiosInstance.interceptors.request.use((requestConfig) => {
  const newConfig = { ...requestConfig };
  const token = localStorage.getItem("token");

  if (token && newConfig.headers)
    newConfig.headers.Authorization = `Bearer ${token}`;

  return newConfig;
});

export default axiosInstance;

import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { formatDate } from "../../../../utils/formatDate";
import DescriptionList from "../../../layout/DescriptionList";
import {
  getLiability,
  getLiabilityStatus,
} from "../../../../services/liability";

const LiabilityDetails: React.FunctionComponent = () => {
  const { id } = useParams();
  const { data: liability } = useQuery(
    ["liabilities", { id }],
    () => id && getLiability(id),
    { keepPreviousData: true }
  );

  return (
    <div className="flex flex-col gap-4 w-full">
      <h3 className="text-2xl font-semibold">View Liability Details</h3>
      <DescriptionList
        fields={[
          { title: "ID", name: "id" },
          { title: "From", name: "seller" },
          { title: "To", name: "buyer" },
          { title: "Price", name: "price" },
          { title: "Status", name: "status" },
          { title: "Created", name: "createdAt" },
        ]}
        data={{
          ...liability,
          price: `$${liability?.price}`,
          status: getLiabilityStatus(liability?.status),
          createdAt: formatDate(liability?.createdAt, "d MMMM yyyy @ hh:mm a"),
        }}
      />
    </div>
  );
};

export default LiabilityDetails;

import { useQuery } from "react-query";

import { getAllTrades, getTradeStatus } from "../../../../services/trade";
import { formatDate } from "../../../../utils/formatDate";
import { formatKilolitres } from "../../../../utils/formatKilolitres";

import Table from "../../../layout/Table";

const Trades: React.FunctionComponent = () => {
  const { data: trades } = useQuery("trades", getAllTrades, {
    keepPreviousData: true,
  });

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-semibold">Manage Trades</h3>
      <p className="text-md text-gray-500">
        List all trades within the Water Ledger database. Note that it is not
        possible to add or edit, as this must be done through the smart
        contracts.
      </p>
      <Table
        fields={[
          { title: "ID", name: "id", linkTo: "/manage/trades/:id" },
          { title: "Price (/ML)", name: "price" },
          { title: "Volume", name: "quantity" },
          { title: "From Level 0 WRS", name: "sellerLevel0ResourceName" },
          { title: "To Level 0 WRS", name: "buyerLevel0ResourceName" },
          { title: "Date", name: "createdAt" },
          { title: "Status", name: "status" },
        ]}
        data={trades?.map((t: any) => ({
          ...t,
          price: `$${t.price}`,
          status: getTradeStatus(t.status),
          quantity: formatKilolitres(t.quantity),
          sellerLevel0ResourceName: t.sellerAccount.level0Resource.name,
          buyerLevel0ResourceName: t.buyerAccount.level0Resource.name,
          createdAt: formatDate(t.createdAt, "d MMMM yyyy"),
        }))}
        noRowsText="No Trades Data"
      />
    </div>
  );
};

export default Trades;

import React from "react";

import MainLayout from "../../layout/MainLayout";
import PageTitle from "../../layout/PageTitle";
import PendingExtractionRight from "./PendingExtractionRight";
import PendingTrades from "./PendingTrades";

const Dashboard: React.FunctionComponent = () => {
  return (
    <MainLayout>
      <div className="w-full flex flex-col gap-10">
        <PageTitle>Dashboard</PageTitle>
        <PendingExtractionRight />
        <PendingTrades />
      </div>
    </MainLayout>
  );
};

export default Dashboard;

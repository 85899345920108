import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  getAllExtractionRight,
  getExtractionRightStatus,
} from "../../../../services/extractionRights";

import BaseButton from "../../../button/BaseButton";
import Table from "../../../layout/Table";

const ExtractionRights: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { data: extractionRights } = useQuery(
    "extractionRights",
    getAllExtractionRight,
    {
      keepPreviousData: true,
    }
  );
  const handleCreateNewClick = () => {
    navigate("/manage/extractionRights/new");
  };

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-2xl font-semibold">Manage Extraction Rights</h3>
      <p className="text-md text-gray-500">
        Edit extraction rights to modify details or add accounts.
      </p>
      <Table
        fields={[
          { title: "ID", name: "id", linkTo: "/manage/extractionRights/:id" },
          { title: "Extraction Right Number", name: "extractionRight" },
          { title: "Status", name: "status" },
          { title: "Remote Address", name: "remoteAddress" },
        ]}
        data={extractionRights?.map((l: any) => ({
          ...l,
          status: getExtractionRightStatus(l.status),
        }))}
        noRowsText="No Extraction Righte Data"
      />
      <div className="flex w-full">
        <BaseButton
          type="button"
          className="flex items-center ml-auto px-3 py-2"
          onClick={handleCreateNewClick}
        >
          <i className="fal fa-plus mr-2" aria-hidden="true" />
          Create New
        </BaseButton>
      </div>
    </div>
  );
};

export default ExtractionRights;

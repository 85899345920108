import { useState } from "react";
import { useMutation } from "react-query";

import BaseButton from "../../../button/BaseButton";
import PrimaryButton from "../../../button/PrimaryButton";
import TextInput from "../../../form/TextInput";

import {
  platforms,
  saveLevel1Resource,
} from "../../../../services/level1Resource";
import Select from "../../../form/Select";
import ErrorMessage from "../../../form/ErrorMessage";

interface ILevel1ResourceDetailsEditProps {
  level1Resource: any;
  onSaveClick: () => void;
  onCancelClick: () => void;
}

const Level1ResourceDetailsEdit: React.FunctionComponent<
  ILevel1ResourceDetailsEditProps
> = ({ level1Resource, onSaveClick, onCancelClick }) => {
  const { mutateAsync: saveLevel1ResourceData, error } = useMutation<any, any>(
    (level1ResourceData) => saveLevel1Resource(level1ResourceData)
  );
  const [currentLevel1Resource, setCurrentLevel1Resource] =
    useState(level1Resource);

  const handleInputChange = (field: string, value: any) => {
    setCurrentLevel1Resource({
      ...currentLevel1Resource,
      [field]: value,
    });
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    await saveLevel1ResourceData(currentLevel1Resource);

    onSaveClick();
  };

  return (
    <form className="space-y-8" onSubmit={handleSave}>
      <div className="space-y-8 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          {error && (
            <ErrorMessage
              message="Save failed"
              details={[
                error?.response?.data?.errors?.[0]?.message ||
                  error?.response?.data?.message,
              ]}
            />
          )}

          <div className="space-y-3 sm:space-y-2">
            <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
              <label
                htmlFor="name"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Name
              </label>
              <div className="sm:col-span-3">
                <TextInput
                  id="name"
                  value={currentLevel1Resource.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
              <label
                htmlFor="identifier"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Identifier
              </label>
              <div className="sm:col-span-3">
                <TextInput
                  id="identifier"
                  value={currentLevel1Resource.identifier}
                  onChange={(e) =>
                    handleInputChange("identifier", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
              <label
                htmlFor="platform"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Platform
              </label>
              <div className="sm:col-span-3">
                <Select
                  name="platform"
                  value={currentLevel1Resource.platform}
                  onChange={(e) =>
                    handleInputChange("platform", Number(e.target.value))
                  }
                >
                  {platforms.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-4 sm:gap-1 sm:items-start">
              <label
                htmlFor="image"
                className="block font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Image
              </label>
              <div className="sm:col-span-3">
                <TextInput
                  id="image"
                  value={currentLevel1Resource.image}
                  onChange={(e) => handleInputChange("image", e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-2 justify-end">
        <BaseButton
          className="flex items-center px-3 py-2"
          type="button"
          onClick={onCancelClick}
        >
          <i className="fal fa-times mr-2" />
          Cancel
        </BaseButton>
        <PrimaryButton className="flex items-center  px-3 py-2" type="submit">
          <i className="fad fa-cloud-upload mr-2" />
          Save
        </PrimaryButton>
      </div>
    </form>
  );
};

export default Level1ResourceDetailsEdit;
